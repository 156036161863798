import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import AgeGate2 from "../../components/agegate"
import Footer from "./FooterPublisher/index"
import { Sessions } from "../../components/Sunsets2023/Sessions"
import { Promotions } from "../../components/Sunsets2023/Promotions"
import Mindset from "../../components/Mindset"
import AgeGateBoolean from "../../components/AgeGate/AgegateBoolean"
import LayoutPopup from "../../components/layoutPopup"

const PublisherPage = ({data, location}) => {
  const [events, setEvents] = useState([])
  const videos = data.allPublisher2023Videos.edges.map((el)=>{
    return el.node
  })




  // useEffect(() => {
  //   const places = data?.allSunset2023?.nodes.filter(elem => {
  //     return elem.visible
  //   })
  //   setEvents(places)
  // }, [])

  return <div>
      <SEO title="Mindset" />
      <Mindset eventos={events} videos={videos}/>
      <Footer />
    </div>
}

export default PublisherPage

export const query = graphql`
   query {
    allPublisher2023Videos {
      edges {
        node {
          index
          link
          title
        }
      }
    }
  }
`

// export const query = graphql`
//   query MainSunset2023Query {
//     allSunset2023 {
//       nodes {
//         date
//         eventName
//         id
//         img
//         img_mobile
//         index
//         link
//         visible
//       }
//     }
//   }
// `
