import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "./mindset.module.scss"
import logo from "../../images/CORONA_STUDIOS_logo.png"
import arrowleft from "../../images/arrow_left.svg"
import grunge from "../../images/grunge_mindset.png"
import grunge_mobile from "../../images/grunge_mindset_mobile.png"
import { IsMobileDevice } from "../../utils/index"
import ReactPlayer from "react-player"

const Mindset = ({ videos }) => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/* <p className={styles.back} onClick={()=>navigate("/sunsets")} > VOLVER </p> */}
        <span onClick={() => navigate("/")}>
          {" "}
          <img src={arrowleft} /> volver
        </span>
        <img
          src={logo}
          alt="corona logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <div style={{marginTop:isMobile? "-10vh":"30vh"}}>
            <h1>
            BIENVENIDOS A <br/>CORONA STUDIOS
            </h1>
            <p className={styles.mindset_p}>Una plataforma donde encontrarás historias únicas creadas por los content creators más talentosos del mundo.</p>
        </div>
        <img src={isMobile ? grunge_mobile :grunge} alt="grunge" className={styles.grunge}/>
      </div>
          <section style={{marginTop:isMobile?0:"15vh"}}>
            {videos.sort((v1, v2) => v1.index - v2.index)
            .map((video)=>{
                   return <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.video_title}>{video.title}</h2>
                    <ReactPlayer
                        url={video.link}
                        frameborder="0"
                        // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        width= {isMobile ? "90vw":"80vw"}
                        height={ isMobile ? "auto":"100%"}
                        controls
                        muted
                        playbackRate={1}
                        progressInterval={1000}
                        onContextMenu={e => e.preventDefault()}
                        config={{
                            file: {
                                attributes: {
                                    playsInline: true,
                                    controlsList: "nodownload",
                                    disableRemotePlayback: true,
                                    disablePictureInPicture: true,
                                },
                            },
                        }}
                        onStart={() => {
                            window.dataLayer.push({
                                'event' : 'GAEvent',
                                'event_category': 'Video',
                                'event_action': `Play`,
                                'event_label': `${video.title}`,
                                'interaction': 'True',
                            });
                        }}
                        onEnded={() => {
                            window.dataLayer.push({
                                'event' : 'GAEvent',
                                'event_category': 'Video',
                                'event_action': `Completed`,
                                'event_label': `${video.title}`,
                                'interaction': 'True',
                            });
                        }}
                    />
                </div>
            })}

          </section>
    </div>
  )
}



export default Mindset
